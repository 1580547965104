@use '@sats-group/ui-lib/tokens/lightmode';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';

.log-in {
  $breakpoint: 950px;

  background-color: lightmode.$background-secondary;
  color: lightmode.$primary;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  @media (min-width: $breakpoint) {
    background-color: lightmode.$background-primary;
    padding: spacing.$s;
    justify-content: center;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    background-color: lightmode.$clean;
    width: 100%;

    @media (min-width: $breakpoint) {
      @include elevation.level(6);
      flex-direction: row;
      border: 1px solid lightmode.$ui-shimmer;
      border-radius: 8px;
      margin: spacing.$m;
      max-width: 1000px;
    }
  }

  &__texts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: spacing.$xl spacing.$l;
    max-width: 555px;
    width: 100%;

    @media (min-width: $breakpoint) {
      padding: spacing.$xl spacing.$xxl;
    }
  }

  &__tagline,
  &__texts,
  &__button {
    margin-bottom: spacing.$xl;
  }

  &__text {
    margin-bottom: spacing.$s;
  }

  &__text-list {
    margin-top: spacing.$xs;
  }

  &__button {
    width: 200px;
  }

  &__image {
    @media (min-width: $breakpoint) {
      height: 100%;
      width: 445px;
      overflow: hidden;
      border-radius: 0 corner-radius.$s corner-radius.$s 0;
    }

    &--desktop {
      @media (max-width: $breakpoint) {
        display: none;
      }
    }

    &--mobile {
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
  }

  &__spinner {
    max-width: 24px;
    flex-grow: 0;
    margin-left: spacing.$xs;
  }
}
